import { default as login1DR4obRhliMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/auth/login.vue?macro=true";
import { default as request_45passwordGLKySCUGn2Meta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/auth/request-password.vue?macro=true";
import { default as _91token_93iZdddzXAoPMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/auth/reset-password/[token].vue?macro=true";
import { default as _91token_93edgKPAGE1sMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/auth/setup-password/[token].vue?macro=true";
import { default as bekabeld2GMS6ci9x6Meta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/beheer/bekabeld.vue?macro=true";
import { default as importc8HsjI7MCcMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/beheer/switch-ports/import.vue?macro=true";
import { default as index7c9ahBxVL1Meta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/beheer/switch-ports/index.vue?macro=true";
import { default as calculatorp7t2PjOikTMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/calculator.vue?macro=true";
import { default as indextPC3OMfGbUMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/index.vue?macro=true";
import { default as indexupdq9hX4xyMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/klanten/[slug]/index.vue?macro=true";
import { default as indexVavyCe3yHUMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/klanten/index.vue?macro=true";
import { default as indexrUlbgIYD8nMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/medewerkers/[uuid]/index.vue?macro=true";
import { default as createj5hG3sTMw8Meta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/medewerkers/create.vue?macro=true";
import { default as index1rsNPSAP1YMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/medewerkers/index.vue?macro=true";
import { default as indexJfP75Bk3sdMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/orders/[slug]/index.vue?macro=true";
import { default as indexQoIVmCJ7TGMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/profile/index.vue?macro=true";
import { default as status7PCczJdCffMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/status.vue?macro=true";
export default [
  {
    name: login1DR4obRhliMeta?.name ?? "auth-login",
    path: login1DR4obRhliMeta?.path ?? "/auth/login",
    meta: login1DR4obRhliMeta || {},
    alias: login1DR4obRhliMeta?.alias || [],
    redirect: login1DR4obRhliMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: request_45passwordGLKySCUGn2Meta?.name ?? "auth-request-password",
    path: request_45passwordGLKySCUGn2Meta?.path ?? "/auth/request-password",
    meta: request_45passwordGLKySCUGn2Meta || {},
    alias: request_45passwordGLKySCUGn2Meta?.alias || [],
    redirect: request_45passwordGLKySCUGn2Meta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/auth/request-password.vue").then(m => m.default || m)
  },
  {
    name: _91token_93iZdddzXAoPMeta?.name ?? "auth-reset-password-token",
    path: _91token_93iZdddzXAoPMeta?.path ?? "/auth/reset-password/:token()",
    meta: _91token_93iZdddzXAoPMeta || {},
    alias: _91token_93iZdddzXAoPMeta?.alias || [],
    redirect: _91token_93iZdddzXAoPMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/auth/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93edgKPAGE1sMeta?.name ?? "auth-setup-password-token",
    path: _91token_93edgKPAGE1sMeta?.path ?? "/auth/setup-password/:token()",
    meta: _91token_93edgKPAGE1sMeta || {},
    alias: _91token_93edgKPAGE1sMeta?.alias || [],
    redirect: _91token_93edgKPAGE1sMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/auth/setup-password/[token].vue").then(m => m.default || m)
  },
  {
    name: bekabeld2GMS6ci9x6Meta?.name ?? "beheer-bekabeld",
    path: bekabeld2GMS6ci9x6Meta?.path ?? "/beheer/bekabeld",
    meta: bekabeld2GMS6ci9x6Meta || {},
    alias: bekabeld2GMS6ci9x6Meta?.alias || [],
    redirect: bekabeld2GMS6ci9x6Meta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/beheer/bekabeld.vue").then(m => m.default || m)
  },
  {
    name: importc8HsjI7MCcMeta?.name ?? "beheer-switch-ports-import",
    path: importc8HsjI7MCcMeta?.path ?? "/beheer/switch-ports/import",
    meta: importc8HsjI7MCcMeta || {},
    alias: importc8HsjI7MCcMeta?.alias || [],
    redirect: importc8HsjI7MCcMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/beheer/switch-ports/import.vue").then(m => m.default || m)
  },
  {
    name: index7c9ahBxVL1Meta?.name ?? "beheer-switch-ports",
    path: index7c9ahBxVL1Meta?.path ?? "/beheer/switch-ports",
    meta: index7c9ahBxVL1Meta || {},
    alias: index7c9ahBxVL1Meta?.alias || [],
    redirect: index7c9ahBxVL1Meta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/beheer/switch-ports/index.vue").then(m => m.default || m)
  },
  {
    name: calculatorp7t2PjOikTMeta?.name ?? "calculator",
    path: calculatorp7t2PjOikTMeta?.path ?? "/calculator",
    meta: calculatorp7t2PjOikTMeta || {},
    alias: calculatorp7t2PjOikTMeta?.alias || [],
    redirect: calculatorp7t2PjOikTMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/calculator.vue").then(m => m.default || m)
  },
  {
    name: indextPC3OMfGbUMeta?.name ?? "index",
    path: indextPC3OMfGbUMeta?.path ?? "/",
    meta: indextPC3OMfGbUMeta || {},
    alias: indextPC3OMfGbUMeta?.alias || [],
    redirect: indextPC3OMfGbUMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexupdq9hX4xyMeta?.name ?? "klanten-slug",
    path: indexupdq9hX4xyMeta?.path ?? "/klanten/:slug()",
    meta: indexupdq9hX4xyMeta || {},
    alias: indexupdq9hX4xyMeta?.alias || [],
    redirect: indexupdq9hX4xyMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/klanten/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexVavyCe3yHUMeta?.name ?? "klanten",
    path: indexVavyCe3yHUMeta?.path ?? "/klanten",
    meta: indexVavyCe3yHUMeta || {},
    alias: indexVavyCe3yHUMeta?.alias || [],
    redirect: indexVavyCe3yHUMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/klanten/index.vue").then(m => m.default || m)
  },
  {
    name: indexrUlbgIYD8nMeta?.name ?? "medewerkers-uuid",
    path: indexrUlbgIYD8nMeta?.path ?? "/medewerkers/:uuid()",
    meta: indexrUlbgIYD8nMeta || {},
    alias: indexrUlbgIYD8nMeta?.alias || [],
    redirect: indexrUlbgIYD8nMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/medewerkers/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: createj5hG3sTMw8Meta?.name ?? "medewerkers-create",
    path: createj5hG3sTMw8Meta?.path ?? "/medewerkers/create",
    meta: createj5hG3sTMw8Meta || {},
    alias: createj5hG3sTMw8Meta?.alias || [],
    redirect: createj5hG3sTMw8Meta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/medewerkers/create.vue").then(m => m.default || m)
  },
  {
    name: index1rsNPSAP1YMeta?.name ?? "medewerkers",
    path: index1rsNPSAP1YMeta?.path ?? "/medewerkers",
    meta: index1rsNPSAP1YMeta || {},
    alias: index1rsNPSAP1YMeta?.alias || [],
    redirect: index1rsNPSAP1YMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/medewerkers/index.vue").then(m => m.default || m)
  },
  {
    name: indexJfP75Bk3sdMeta?.name ?? "orders-slug",
    path: indexJfP75Bk3sdMeta?.path ?? "/orders/:slug()",
    meta: indexJfP75Bk3sdMeta || {},
    alias: indexJfP75Bk3sdMeta?.alias || [],
    redirect: indexJfP75Bk3sdMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/orders/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexQoIVmCJ7TGMeta?.name ?? "profile",
    path: indexQoIVmCJ7TGMeta?.path ?? "/profile",
    meta: indexQoIVmCJ7TGMeta || {},
    alias: indexQoIVmCJ7TGMeta?.alias || [],
    redirect: indexQoIVmCJ7TGMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: status7PCczJdCffMeta?.name ?? "status",
    path: status7PCczJdCffMeta?.path ?? "/status",
    meta: status7PCczJdCffMeta || {},
    alias: status7PCczJdCffMeta?.alias || [],
    redirect: status7PCczJdCffMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/management/pages/status.vue").then(m => m.default || m)
  }
]
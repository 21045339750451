import validate from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ro_4js5rrrwza45y4nm4wvr24sbb4/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-cor_pecyofjme6cyfh6gxgjec7szp4/node_modules/@bttr-devs/nuxt-layer-base/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.4_eslint@8.57.0_floating-vue@5.2.2_ro_4js5rrrwza45y4nm4wvr24sbb4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sofie-page": () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.2_@nuxt+devtools@1.3.3_@unhead+shared@1.9.12_@vue+compiler-co_clox342lea74mcr4od5jcv2eeu/node_modules/@bttr-devs/nuxt-layer-sofie/middleware/sofie-page.ts"),
  auth: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_@opentelemetry+api@1.8.0_next-auth@4.21.1_rollup@4.18.0/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}